import * as React from "react"
import Layout from '../components/Layout.js';

const Disclaimer = () => {
  return (
    <Layout>
    </Layout>
  )
}

export default Disclaimer